









































































































































import Vue from "vue";
import moment from "moment";
import { ResponsePagination } from "@/models/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { generalJournalServices } from "@service/general-journal.service";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { ResponseListGeneralJournal } from "@interface/general-journal.interface";
import { downloadFile } from "@/helpers/file-reader";
import MNotificationVue from "@/mixins/MNotification.vue";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import { GENERAL_JOURNAL_STATUS } from "@/models/enums/general-journal.enum";
import { mapGetters } from "vuex";
import { getauthorities } from "@/helpers/cookie";
export default Vue.extend({
  name: "ListJournal",
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      form: {
        docNumber: undefined as string | undefined,
        dateFrom: null as string | null,
        dateTo: null as string | null
      },
      limit: DEFAULT_PAGE_SIZE as number,
      page: 0 as number,
      search: "" as string,
      loading: {
        table: false,
        download: false,
      },
      dataListJournal: {} as ResponseListGeneralJournal,
      columnsTable: [
        {
          title: this.$t("lbl_document_number"),
          dataIndex: "name",
          key: "docNumber",
          sorter: false,
          width: 120,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_source"),
          dataIndex: "source",
          key: "source",
          sorter: false,
          width: 90,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_date"),
          dataIndex: "date",
          key: "date",
          sorter: false,
          width: 90,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          sorter: false,
          width: 180,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_document_reference"),
          dataIndex: "documentReference",
          key: "docReference",
          sorter: false,
          width: 140,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_amount"),
          dataIndex: "total",
          key: "amount",
          sorter: false,
          width: 90,
          scopedSlots: { customRender: "isCurrency" },
        },
        // {
        //   title: this.$t("lbl_action"),
        //   dataIndex: "operation",
        //   key: "operation",
        //   scopedSlots: { customRender: "operation" },
        //   button: ["view"],
        //   width: 100,
        //   align: "center",
        // }
      ] as any,
      formRules: {
        docNumber: {
          label: "lbl_document_number",
          name: "Doc Number",
          placeholder: "lbl_type_here",
        },
        dateFrom: {
          label: "lbl_date_from",
          name: "Date From",
          placeholder: "lbl_choose",
        },
        dateTo: {
          label: "lbl_date_to",
          name: "Date To",
          placeholder: "lbl_choose",
        },
      },
      authPrivilege: [] as string[],
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "general-journal" && x.privilege.update && x.privilege.create);
    },
    formItemLayout() {
      return {
        labelCol: { span: 5 },
        wrapperCol: { span: 10 },
      };
    },
  },
  created() {
    this.setAuthorities();
    this.getListGeneralJournal();
  },
  methods: {
    moment,
    setAuthorities(): void {
      const auth = getauthorities();
      auth.forEach(dataAuth => {
        if(dataAuth.actions?.length && dataAuth?.name === "general-journal") {
          this.authPrivilege = dataAuth.value;
          this.columnsTable.push(
            {
              title: this.$t("lbl_action"),
              dataIndex: "operation",
              key: "operation",
              scopedSlots: { customRender: "operation" },
              button: dataAuth.actions,
              width: 120,
              align: "center",
              fixed: "right",
            },
          );
        }
      });
    },
    gotoCreate(): void {
      this.$router.push({ name: "generaljournal.create" });
    },
    handleClear(): void {
      this.form = {
        docNumber: undefined as string | undefined,
        dateFrom: null as string | null,
        dateTo: null as string | null
      };
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListGeneralJournal();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page - 1;
      this.getListGeneralJournal();
    },
    reponseViewTable({ data }): void {
      let { id, status } = data;
      if (status === GENERAL_JOURNAL_STATUS.POSTED) {
        this.$router.push({ name: "generaljournal.detail", params: { id }});
      } else {
        this.$router.push({ name: "generaljournal.update", params: { id }});
      }
    },
    submitForm(): void {
      const form = { ...this.form };
      let arrSearch: string[] = [];
      for (const key in form) {
        if (Object.prototype.hasOwnProperty.call(form, key)) {
          const element = form[key];
          this.search = "";
          if (key === "dateFrom" && element)
            arrSearch.push(
              `date>=${this.moment(element)
                .set({
                  hour: 0,
                  minute: 0,
                  second: 0
                })
                .format()}`
            );
          if (key === "dateTo" && element)
            arrSearch.push(
              `date<=${this.moment(element)
                .set({
                  hour: 23,
                  minute: 59,
                  second: 59
                })
                .format()}`
            );
          if (key === "docNumber" && element) arrSearch.push(`name~${element}`);
        }
      }
      if (arrSearch.length > 0) this.search = arrSearch.join("_AND_");
      this.getListGeneralJournal();
    },
    getListGeneralJournal(): void {
      let params = {
        limit: this.limit,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      if (this.search) {
        params.search = this.search;
        params.page = 0;
      } else {
        params.page = this.page;
      }
      this.loading.table = true;
      generalJournalServices
        .listGeneralJournal(params)
        .then((res) => {
          res.data.forEach((dataObject, index) => {
            dataObject["key"] = index;
            dataObject.date = this.moment(dataObject.date).format(DEFAULT_DATE_FORMAT);
          });
          this.dataListJournal = res;
        })
        .catch(() => this.showErrorMessage("notif_process_fail"))
        .finally(() => (this.loading.table = false));
    },
    async downloadList(): Promise<void> {
      try {
        this.loading.download = true;
        const params: RequestQueryParamsModel = {
          limit: this.dataListJournal.totalElements,
          page: 0,
          sorts: "createdDate:desc",
        };
        if (this.search) params.search = this.search;
        const file = await generalJournalServices.downloadList(params);
        downloadFile(new Blob([file]), "report_journal_list.xlsx");
      } catch (error) {
        this.showErrorMessage("notif_download_error");
      } finally {
        this.loading.download = false;
      }
    },
  }
});
