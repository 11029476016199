var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_general_journal") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            _vm._b(
              { attrs: { model: _vm.form, "label-align": "left" } },
              "a-form-model",
              _vm.formItemLayout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.docNumber.label) } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: _vm.$t(_vm.formRules.docNumber.placeholder)
                    },
                    model: {
                      value: _vm.form.docNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "docNumber", $$v)
                      },
                      expression: "form.docNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.dateFrom.label) } },
                [
                  _c("a-date-picker", {
                    staticClass: "w-100",
                    attrs: {
                      placeholder: _vm.$t(_vm.formRules.dateFrom.placeholder),
                      format: _vm.DEFAULT_DATE_FORMAT
                    },
                    model: {
                      value: _vm.form.dateFrom,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "dateFrom", $$v)
                      },
                      expression: "form.dateFrom"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.dateTo.label) } },
                [
                  _c("a-date-picker", {
                    staticClass: "w-100",
                    attrs: {
                      placeholder: _vm.$t(_vm.formRules.dateTo.placeholder),
                      format: _vm.DEFAULT_DATE_FORMAT
                    },
                    model: {
                      value: _vm.form.dateTo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "dateTo", $$v)
                      },
                      expression: "form.dateTo"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleClear } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.submitForm }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12, align: "end" } },
                [
                  _vm.hasPrivilegeCreate
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "plus" },
                          on: { click: _vm.gotoCreate }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_create_new")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c("TableCustom", {
                    attrs: {
                      idtable: "table1",
                      "on-select-change": null,
                      "selected-row-keys": null,
                      "data-source": _vm.dataListJournal.data,
                      columns: _vm.columnsTable,
                      scroll: { x: 1400, y: 520 },
                      paginationcustom: true,
                      "default-pagination": false,
                      loading: _vm.loading.table
                    },
                    on: {
                      "on-delete": function() {},
                      "on-edit": _vm.reponseViewTable
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("Pagination", {
                    attrs: {
                      total: _vm.dataListJournal.totalElements,
                      "page-size-set": _vm.limit,
                      "id-pagination": "pagination1"
                    },
                    on: {
                      "response-pagesize-change": _vm.responsePageSizeChange,
                      "response-currentpage-change":
                        _vm.responseCurrentPageChange
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12, align: "end" } },
                [
                  _c("a-tag", { attrs: { color: "#8c8c8c" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lbl_total_found")) +
                        " : " +
                        _vm._s(
                          _vm.dataListJournal.totalElements
                            ? _vm.dataListJournal.totalElements
                            : 0
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { align: "end", span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "download",
                        loading: _vm.loading.download
                      },
                      on: { click: _vm.downloadList }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }